<template>
    <div class="html-frame">
        <iframe id="hframe" :src="url"  frameborder="0" :style="'transform: translateX(0px); width:'+ width +'; height:' + height"></iframe> 
        <!-- <iframe id="hframe" :src="url" frameborder="1" style="width: 1500px; transform: scale(0.5 ) translateX(-500px) translateY(-300px); height:600px"></iframe>  -->
    </div>
</template>

<script>

export default {
  name: 'HtmlFrame',
  props: {
    width:  {
      typeof: [String],
      default: "950px",
    },
    height:  {
      typeof: [String],
      default: "850px",
    },
    url: {
      typeof: [String],
      require: true,
    },
  },
  
  mounted() {
    
    const iframe = document.querySelector('#hframe')
    // 处理兼容行问题
    if (iframe.attachEvent) {
      iframe.attachEvent('onload', function () {
        // iframe加载完毕以后执行操作
        // console.log('attachEvent iframe已加载完毕')
        this.style.height = this.contentWindow.document.documentElement.scrollHeight + 50+ "px"
        // this.style.width = this.contentWindow.document.documentElement.scrollWidth + 50+ "px"
      })
    } else {
      iframe.onload = function () {
        // iframe加载完毕以后执行操作
        // console.log('onload iframe已加载完毕')
        this.style.height = this.contentWindow.document.documentElement.scrollHeight + 10+ "px"
        this.style.width = this.contentWindow.document.documentElement.scrollWidth + "px"
        //  console.log("  this.width "+this.width)
        // console.log( "  this.contentWindow.document.documentElement.scrollWidth " + this.contentWindow.document.documentElement.scrollWidth)  
      }
    }    
    console.log("  this.width "+this.width)
    console.log( "  iframe.style.width " + iframe.style.width)  
  },
  methods: {
    info(doc){
      console.log("   doc", doc)
      console.log("   scrollHeight", doc.documentElement.scrollHeight)
      console.log("   scrollWidth", doc.documentElement.scrollWidth)
    },
  },
};
</script>

<style scoped lang="less">
  .html-frame {
    padding: 0px;
    width: 100%;
    // width: 1100px;
    height: 100%;
    // display: flex;
    // justify-content: center;
    // border: 1px solid  #0f0; 
    
  }

</style>
