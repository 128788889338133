<template>
 
        <html-detail :newsdeta = "patent"/>
     
</template>

<script>

import htmlDetail from "@/components/all/pdfHtmlDetail"; //引入尾部组件

export default {
  name: 'AnalyseDetail',
  components: {
    htmlDetail,
  },

  data() {
    return {   
      patent: {},
      id: '',
      title: '',
    }
  },
  
  created() {
    this.init()
    this.getdata();
  },
  methods: {
    init(){
      this.id = this.$route.params.id
      console.log("   patent id " ,   this.id)
    },
    getdata() {
      this.$api.getInfoDetail(this.id).then((res) => {
        this.patent = res.data;
        this.title = this.patent.title
        console.log("  get patent " , this.patent)
      });
    },
  },
};
</script>

<style scoped lang="less">


</style>
