<template>
  <div class="detailbox">
    <div class="flexcent title">
      {{ newsdeta.title || newsdeta.name }}
    </div>
    <div class="flexcent time"  v-if="newsdeta.createdTime">
      发布时间：{{ newsdeta.createdTime | FormatDate('yyyy-MM-dd')}}
    </div>
    <div class="content">
     <html-frame v-if="newsdeta.contentUrl"  :url = "newsdeta.contentUrl" />
     <html-frame v-else-if="newsdeta.fileUrl" :url = "newsdeta.fileUrl" :height="height"/> 

    </div>
  </div>
</template>

<script>
import htmlFrame from "@/components/all/pdfHtmlFrame"; //引入尾部组件

export default {
  name: "detail",  
  components: {
    htmlFrame
  },

  props: {
    newsdeta: {
      typeof: [Object],
    },
    height: {
      typeof: [String],
      default: "850px",
    }
  },
  created() {
    console.log(this.newsdeta);
  },
};
</script>


<style scoped lang="less">

.detailbox{
  //  border: 0.0625rem solid #f00;
    .content {
        /deep/ p {
          font-size: 0.875rem;
          margin:20px auto;
        }
    }
    .title {
      margin-top: 1.25rem;
      text-align: center;
      font-weight: 550;
      font-size: 1rem;
    }
    .time {
      height: 2rem;
      font-size: 0.875rem;
      padding-bottom: 0.5rem;
      border-bottom: 0.0625rem solid #ececec;
    }
  }


@media screen and (min-width: 1000px) {
  .title {
    height: 3.125rem;
    font-weight: 550;
    font-size: 1.5rem;
  }
  .time {
    height: 2rem;
    font-size: 0.875rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.0625rem solid #ececec;
  }
  // .content {
  //  border: 2px solid #f00;
  //   padding: 2.5rem 0.9375rem;
  //   /deep/ p {
  //     font-size: 0.875rem;
  //   }
  // }
}

@media screen and (max-width: 999px) {
  .contentbox{
    padding: 0 .9375rem;
    box-sizing: border-box;
    width: 100%;
    .content {
        /deep/ p {
          font-size: 0.875rem;
        }
    }
    .title {
      margin-top: 1.25rem;
      text-align: center;
      font-weight: 550;
      font-size: 1rem;
    }
    .time {
      height: 2rem;
      font-size: 0.875rem;
      padding-bottom: 0.5rem;
      border-bottom: 0.0625rem solid #ececec;
    }
  }
 

}
</style>
